import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/docs/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "consents"
    }}>{`Consents`}</h1>
    <p>{`The Consent API allows you to create, delete, update and manage all your consents. Get started quickly by using our predefined consent bases or create your own custom consents.`}</p>
    <p>{`With the consent API you can:`}</p>
    <ul>
      <li parentName="ul">{`Manage all your customers consents`}</li>
      <li parentName="ul">{`Use pre-defined consents without worrying about GDPR`}</li>
      <li parentName="ul">{`Create custom consents`}</li>
      <li parentName="ul">{`Integrate with your CRM`}</li>
    </ul>
    <p>{`You can find the complete reference to the Consents API here: `}<a parentName="p" {...{
        "href": "/pages-customers-docs-customers-api"
      }}>{`https://developer.entur.org/pages-customers-docs-customers-api`}</a></p>
    <h3 {...{
      "id": "data-model"
    }}>{`Data model`}</h3>
    <p>{`To be able to register a consent there several things that you need to be aware of. The consent service contains three models:`}</p>
    <p><img alt="Data Model" src={require("./data_model.png")} /></p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Consent Base`}</strong>{`: The consent base contains the legal information of a consent.
A consent base can be shared across multiple organisation because the terms can be generic and reusable.
Furthermore, the consent base has a field called `}<inlineCode parentName="li">{`consentOwnerOrgId`}</inlineCode>{`.
If set, the organisation which owns the consent, and can use it exclusively.
Keep this field blank for the consents that are shared between multiple organisations.
Note that ConsentTerms and InternalDescription are modelled separately.
If ConsentTerms are changed, a new version of the ConsentBase is created.
It is possible to add other language versions of the terms without creating a new version.`}</li>
      <li parentName="ul"><strong parentName="li">{`Consent`}</strong>{`: While the consent base holds information about what the consent entails, consent provides the link to each
organisation and the type of communication that is supported. We say that we instantiate a consent base with a consent.`}</li>
      <li parentName="ul"><strong parentName="li">{`Given Consent`}</strong>{`: A given consent is the connection between the customer and what consent the customer consented to.`}</li>
    </ul>
    <h3 {...{
      "id": "versioning"
    }}>{`Versioning`}</h3>
    <p>{`A consent base can have 1 or more versions. The versions has 3 different modes: draft, active, inactive. These modes are controlled by the fields `}<inlineCode parentName="p">{`validFrom`}</inlineCode>{` and `}<inlineCode parentName="p">{`validTo`}</inlineCode>{`. `}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Draft`}</strong>{`: If `}<inlineCode parentName="li">{`validFrom`}</inlineCode>{` is not set or set to a datetime in the future.`}</li>
      <li parentName="ul"><strong parentName="li">{`Active`}</strong>{`: If `}<inlineCode parentName="li">{`validFrom`}</inlineCode>{` is set to a datetime in the past and `}<inlineCode parentName="li">{`validTo`}</inlineCode>{` is not set or set to a datetime in the future`}</li>
      <li parentName="ul"><strong parentName="li">{`InActive`}</strong>{`: If `}<inlineCode parentName="li">{`validTo`}</inlineCode>{` is set to a datetime in the past`}</li>
    </ul>
    <p>{`A consent base can not update fields after it has become active, with the exception of `}<inlineCode parentName="p">{`internalDescription`}</inlineCode>{` and adding terms in new languages.
To retrieve a consent base that are in draft-mode, you need to specify explicit the request to include all versions. See docs for reference.  `}</p>
    <p>{`Consent has the same fields `}<inlineCode parentName="p">{`validFrom`}</inlineCode>{` and `}<inlineCode parentName="p">{`validTo`}</inlineCode>{` to determine if it is active or not. These fields are partial controlled by the consent
bases `}<inlineCode parentName="p">{`validFrom`}</inlineCode>{` and `}<inlineCode parentName="p">{`validTo`}</inlineCode>{`. A consents `}<inlineCode parentName="p">{`validFrom`}</inlineCode>{`cannot be set before the consent base `}<inlineCode parentName="p">{`validFrom`}</inlineCode>{` and a consents `}<inlineCode parentName="p">{`validTo`}</inlineCode>{`cannot be set
after the consent base `}<inlineCode parentName="p">{`validTo`}</inlineCode>{`. If a consent base shortens its `}<inlineCode parentName="p">{`validTo`}</inlineCode>{`, it will cascade to consents `}<inlineCode parentName="p">{`validTo`}</inlineCode>{`.`}</p>
    <p>{`Because of GDPR you are not allowed to change a consent without informing the customers, you will have to create a new version to change the
terms and obtain new given consents from your users. `}<strong parentName="p">{`Important!`}</strong>{` The given consent (the decision the customer have made) won’t be deleted
unless the user has consented to the new version. When the customer has registered a new given consent and the consent is valid, the previous
given consent will be deleted automatically.`}</p>
    <p>{`This means that the state diagram for a consent looks like this:
`}<img alt="consentbase-change-terms" src={require("./consents-documentation-change-terms.png")} />{`  `}</p>
    {/* Note: image above is saved from gliffy at https://enturas.atlassian.net/wiki/x/dwAfN */}
    <p>{`Useful keywords:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`ConsentOwnerOrgId`}</strong>{` describes which partner that owns the consent base.
In the case that the value is null (empty), it is available for all partners.`}</li>
      <li parentName="ul"><strong parentName="li">{`OrganisationId`}</strong>{` describes the partner that instantiated the consent base into a consent.`}</li>
      <li parentName="ul"><strong parentName="li">{`CreatedByOrgId`}</strong>{` describes the partner that obtained the givenConsent from the customer.`}</li>
      <li parentName="ul"><strong parentName="li">{`CustomerNumber`}</strong>{` is the unique identifier of a customer in Entur systems.
All customers, regardless of owning organisation, has a customer number.`}</li>
      <li parentName="ul"><strong parentName="li">{`CustomerRef`}</strong>{` (customer reference) identifies a customer within an organisation, and can be provided
by the external organisation during customer creation. It is required to be unique within an organisation, if provided`}</li>
      <li parentName="ul"><strong parentName="li">{`ConsentCode`}</strong>{` is both used as a unique identifier of a consent base and used among administrators to quickly know what kind of consent it is
without having to read the terms and description.`}</li>
      <li parentName="ul"><strong parentName="li">{`Traveller`}</strong>{` A customer registered on an order as a traveller.`}</li>
      <li parentName="ul"><strong parentName="li">{`Agent`}</strong>{` The entity performing the sale. This may be the `}<em parentName="li">{`operator`}</em>{` but it doesn't have to be.`}</li>
      <li parentName="ul"><strong parentName="li">{`Operator`}</strong>{` The entity responsible for the transport on a `}<em parentName="li">{`leg`}</em>{` in a given order. `}</li>
      <li parentName="ul"><strong parentName="li">{`Leg`}</strong>{` a part of a journey, from one stop to the next change. For instance, travelling Bergen->Oslo->Eidsvoll has two `}<em parentName="li">{`legs`}</em>{`, and it may or may not be the same `}<em parentName="li">{`operator`}</em>{`.`}</li>
    </ul>
    <p>{`Consents are collected from the travellers by the agents and then distributed to the operators through a kafka-topic `}<a parentName="p" {...{
        "href": "/pages-customers-docs-real-time-updates"
      }}>{`[read more]`}</a>{`. `}</p>
    <p><img alt="Communication between traveller, agent and operator" src={require("./external-communication-overview.png")} /></p>
    {/* image from https://enturas.atlassian.net/wiki/spaces/~627214531/pages/803995934/Connections */}
    <h3 {...{
      "id": "use-cases"
    }}>{`Use cases`}</h3>
    <h4 {...{
      "id": "register-a-new-givenconsent-on-an-existing-consent"
    }}>{`Register a new GivenConsent on an existing Consent`}</h4>
    <p>{`An Agent kan register the consents for a customer through the given-consents api:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`curl -X POST \\
  https://api.entur.io/customers/v2/consents/client/given-consents \\
  -H  "Authorization: Bearer $PARTNER_TOKEN" \\
  -H  "Content-Type: application/json" \\
  -d '{
    "customerRef" : "DEMO__1",
    "customerNumber": 1234567,
    "consentId" : 715,
    "consentChoice" : true,
    "email" : "test.testesen@entur.org",
    "telephoneNumber" : "12345678",
    "countryCode": "+47",
    "expirationDate" : "2029-11-01T12:00:00+0100"
}'

# Output: {"id":7330,"customerNumber":1234...

`}</code></pre>
    <h4 {...{
      "id": "get-available-consents"
    }}>{`Get available consents`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`curl \\        
  https://api.entur.io/customers/v2/consents/admin/consents \\ 
  -H  "Authorization: Bearer $PARTNER_TOKEN"
  
# Output: 
#
#[
#  {
#    "id": 711,
#    "consentBase": {
#      "consentCode": "CONSENTS_API_TEST",
#      "terms": [...],
#      "validFrom": "2018-11-01T12:00:00+0000",
#      "validTo": "2118-11-01T12:00:00+0000",
#      "version": 1
#      ...
#    },
#    "organisationId": 399,
#    ...
#  },
#  {
#    "id": 715,
#    "consentBase": {
#      "consentCode": "DEMO",
#      "consentOwnerOrgId": ...,
#      "terms": [...],
#      "validFrom": "2019-09-01T00:00:00+0000",
#      "version": 1,
#      ...
#    },
#    "organisationId": ...,
#    ...
#  }
#]
`}</code></pre>
    <p>{`This list of Consents and ConsentBases are available to you to send in GivenConsents on.
If you `}<em parentName="p">{`own`}</em>{` the ConsentBase, it will have your `}<inlineCode parentName="p">{`consentOwnerOrgId`}</inlineCode>{`, and only you will se consents
connected to it. If it has no `}<inlineCode parentName="p">{`consentOwnerOrgId`}</inlineCode>{`, all organisations can connect to it and you will
see all available consents.  `}</p>
    <h4 {...{
      "id": "fetch-givenconsents"
    }}>{`Fetch GivenConsents`}</h4>
    <p>{`If you are listening to the kafka topic mentioned above, you will receive GivenConsentChanged events
`}<a parentName="p" {...{
        "href": "/pages-customers-docs-real-time-updates"
      }}>{`[See here]`}</a>{` containing no GDPR data, but with the relevant ids.
When fetching data, to avoid DoSing the server, please use the bulk API if at all possible:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`curl -X POST \\
  https://api.entur.io/customers/v2/consents/client/given-consents/get-by/bulk \\
  -H  "Authorization: Bearer $PARTNER_TOKEN" \\
  -H  "Content-Type: application/json" \\
  -d '{
    "customerNumbers": [1234567,1234568]
}'

# Output:
#[
#  {
#    "id": 7330,
#    "customerNumber": 1234567,
#    "customerRef": "DEMO__1",
#    "consentId": 715,
#    "consent": {
#      "id": 715,
#      "consentCode": "DEMO",
#      "consentBase": {
#        "consentCode": "DEMO",
#        "terms": [...],
#        "validFrom": "2019-09-01T00:00:00+0000",
#        "version": 1,
#        "consentOwnerOrgId": __,
#        "orgNameSubstitutionPattern": "[ORG]",
#        "createdAt": "2019-09-05T11:54:38+0000",
#        "changedAt": "2019-09-05T11:54:38+0000"
#      },
#      "organisationId": __,
#      "isEmailSupported": true,
#      "isSmsSupported": true,
#      "validFrom": "2019-09-01T00:00:00+0000",
#      "createdAt": "2019-09-05T12:00:27+0000",
#      "changedAt": "2019-09-05T12:00:27+0000",
#      "emailSupported": true,
#      "smsSupported": true
#    },
#    "consentChoice": true,
#    "email": "test.testesen@entur.org",
#    "telephoneNumber": "12345678",
#    "countryCode": "+47",
#    "expirationDate": "2029-11-01T11:00:00+0000",
#    "createdByOrgId": __,
#    "createdAt": "2019-09-05T12:18:56+0000",
#    "changedAt": "2019-09-05T12:18:56+0000"
#  },
# ...
#]

`}</code></pre>
    <h4 {...{
      "id": "create-a-new-consentbase-and-consent-unique-for-your-organisation"
    }}>{`Create a new ConsentBase and Consent, unique for your organisation`}</h4>
    <p>{`If none of the existing Consents or ConsentBases fit your use case, you can create your own.
This will typically be done through the Entur Partner interface. This is what is happening on the API. `}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`curl -X POST \\
  https://api.entur.io/customers/v2/consents/admin/consent-bases \\
  -H  "Authorization: Bearer $PARTNER_TOKEN" \\ 
  -H  "Content-Type: application/json" \\
  -d '{
    "consentCode": "DEMO",
    "internalDescription": [
        {      
          "description": "This is a demo consent-base created to show how it can be done. This text is only shown in Entur Partner.",
          "languageCode": "ENG"
        },
        {      
          "description": "Dette er en demo consent-base som viser hvordan man lager en slik. Denne tekst vises kun i Entur Partner.",
          "languageCode": "NOB"
        }
    ],  
    "orgNameSubstitutionPattern": "[ORG]",
    "terms": [
        {
          "fullDescription": "These are the full Terms of this consent-base. They may span multiple\\n lines and may contain markup tags:\\n##A header.\\nSome **bold** text. This text can be edited via the Entur Partner interface, but only until the validFrom date is passed.",
          "languageCode": "ENG",
          "shortDescription": "Demo consent. This is the shortDescription shown in dropdowns and on mobile devices."
        },
        {
          "fullDescription": "Her kan skrives den fulle tekst for det man sier ja til når man takker ja til dette samtykket.\\nMan kan skrive på flere linjer, og bruke _markup_. Teksten kan senere endres, hvis ikke validFrom er passert.",
          "languageCode": "NOB",
          "shortDescription": "Demo samtykke. Dette er shortDescription som brukes i lister og overskrifter."
        },
        {
          "fullDescription": "Tjenesten støtter også nynorsk.",
          "languageCode": "NNO",
          "shortDescription": "Demo samtykke på nynorsk."
        }
    ],
    "validFrom": "2019-09-01T00:00+00:00",
    "validTo": null
  }'


# Output: {"consentCode":"DEMO","terms":[{...
`}</code></pre>
    <p>{`The above creates a ConsentBase `}<em parentName="p">{`for your organisation only`}</em>{`. Note for internal developers - if supplying an Internal token, this is accessible for all organisations; use `}<inlineCode parentName="p">{`consentOwnerOrgId`}</inlineCode>{` to limit this.
The first version always has version=1, so to add a Consent:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`curl -X POST \\
  https://api.entur.io/customers/v2/consents/admin/consents \\
  -H  "Authorization: Bearer $PARTNER_TOKEN" \\
  -H  "Content-Type: application/json" \\
  -d '{
    "organisationId": _,
    "consentBaseVersion": 1,
    "consentCode": "DEMO",
    "isEmailSupported": true,
    "isSmsSupported": true,
    "validFrom": "2019-09-01T00:00+00:00",
    "validTo": null
  }'


# Output: {"id":715,"consentCode":"DEMO"...
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      